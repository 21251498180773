.collapse-button-container {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 80px;
	z-index: 1;
	background: white;
	border-top: 1px solid #f0f0f0;
}

.project-layout-sider.collapsed ~ .collapse-button-container {
	width: 80px;
}

.project-layout-sider:not(.collapsed) ~ .collapse-button-container {
	width: 20%;
}

.settings-root {
	margin: 10px;
	background-color: white;
	min-height: 90vh;
}

.default-selection {
	background-color: rgb(0, 120, 63, 0.5);
}

.ant-input-search-button {
	margin: 0;
	padding: 0;
}

.card-settings {
	margin: 5px;
	border: #dbdbdb 1px solid;
}
